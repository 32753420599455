import { Injectable, inject, signal } from '@angular/core'
import { Observable, from, map, of } from 'rxjs'
import { Device } from '../models/Device'
import { AppStateService } from '../store/state/app/app.state.service'
import { NotificationService } from './notification.service'

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  browser = signal('')
  locationDisabled = signal(false)

  #notificationService = inject(NotificationService)
  #appStateService = inject(AppStateService)

  async initDevice() {
    this.locationDisabled.set(false)
    this.browser.set('')
    return await Promise.all([this.getGeoCoordinates()])
      .then(([position]) => {
        if (position instanceof GeolocationPositionError) {
          this.locationDisabled.set(true)
          this.browser.set(this.getBrowserInfo().name)
          // this.#notificationService.showError(Message.ErrorMessageLocationAccess)
          return
        }
        const device = this.#constructDevice(position as unknown as GeolocationPosition)
        this.#appStateService.setDevice(device)
      })
  }

  #constructDevice(position: GeolocationPosition): Device {
    const geoAccuracy = position.coords.accuracy
    const userAgent = navigator.userAgent
    
    const platformInfo = this.getPlatformInfo()
    const deviceType = this.getDeviceType()
    const browserInfo = this.getBrowserInfo()
    const browserIsMobile = deviceType === 'Mobile' || deviceType === 'Tablet'
    const browserMobileModel = browserIsMobile ? this.getMobileModel() : ''
    return {
      platform: platformInfo,
      deviceType: deviceType,
      browserName: browserInfo.name,
      userAgent,
      browserIsMobile,
      browserMobileModel,
      geoAccuracy,
      latitude: position.coords.latitude.toString(),
      longitude: position.coords.longitude.toString()
    }
  }

  getGeoCoordinates(): Promise<GeolocationPosition | GeolocationPositionError> {
    return new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position)
        },
        (error) => {
          resolve(error)
        },
        { enableHighAccuracy: true }
      )
    })
  }

  getHashForDeviceId(uuid: string, email: string): Observable<string> {
    if (localStorage.getItem(email)) {
      return of(localStorage.getItem(email))
    }
    const encoder = new TextEncoder()
    const data = encoder.encode(uuid + email)
    return from(crypto.subtle.digest('SHA-256', data)).pipe(map((hashBuffer) => {
      const hashArray = Array.from(new Uint8Array(hashBuffer))
      const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
      localStorage.setItem(email, hashHex)
      return hashHex
    }))
  }

  getPlatformInfo() {
    const ua = navigator.userAgent
    if (/Macintosh/.test(ua)) return 'Apple'
    if (/Windows/.test(ua)) return 'Windows'
    if (/Android/.test(ua)) return 'Android'
    if (/Linux/.test(ua)) return 'Linux'
    if (/iPhone|iPad|iPod/.test(ua)) return 'Apple'
    return 'Unknown'
  }

  getDeviceType(): 'Tablet' | 'Mobile' | 'Web' {
    const ua = navigator.userAgent
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'Tablet'
    }
    if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/.test(ua)) {
      return 'Mobile'
    }
    return 'Web'
  }

  getMobileModel() {
    const userAgent = navigator.userAgent
    const modelRegex = /(Galaxy|iPhone|Pixel|tablet|ipad|playbook|silk|Mate|P|Find)/i;
  
    const modelMatch = userAgent.match(modelRegex);
  
    if (modelMatch) {
      return `${modelMatch[1]}`;
    }
    return ''
  }

  getBrowserInfo() {
    const userAgent = navigator.userAgent;

    let browserName = 'Unknown';
    let browserVersion = 'Unknown';

    // Check for common browsers
    if (userAgent.indexOf('Edge') > -1 || userAgent.indexOf('Edg') > -1) {
        browserName = 'Edge';
        browserVersion = userAgent.match(/Edg\/(\d+\.\d+)/)?.[1];
    } else if (userAgent.indexOf('Chrome') > -1 && userAgent.indexOf('CriOS') > -1) {
        // Chrome on iOS
        browserName = 'Chrome (iOS)';
        browserVersion = userAgent.match(/CriOS\/(\d+\.\d+)/)?.[1];
    } else if (userAgent.indexOf('Chrome') > -1) {
        browserName = 'Chrome';
        browserVersion = userAgent.match(/Chrome\/(\d+\.\d+)/)?.[1];
    } else if (userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1) {
        browserName = 'Safari';
        browserVersion = userAgent.match(/Version\/(\d+\.\d+)/)?.[1];
    } else if (userAgent.indexOf('Firefox') > -1) {
        browserName = 'Firefox';
        browserVersion = userAgent.match(/Firefox\/(\d+\.\d+)/)?.[1];
    } else if (userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident') > -1) {
        browserName = 'IE';
        browserVersion =
            userAgent.match(/MSIE (\d+\.\d+)/)?.[1] || userAgent.match(/rv:(\d+\.\d+)/)?.[1];
    } else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
        browserName = 'Opera';
        browserVersion = userAgent.match(/Version\/(\d+\.\d+)/)?.[1];
    }
    return { name: browserName, version: browserVersion }
  }

}