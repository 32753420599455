import { Component, OnInit, effect, inject } from '@angular/core'
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms'
import { Observable, finalize, tap } from 'rxjs';
import { SecurityQA, UserDetails } from '../../../common/models/Login';
import { MfaService } from '../../../common/services/mfa/mfa.service';
import { BaseComponent } from '../../../common/components/base.component';
import { UserStateService } from '../../../common/store/state/user/user.state.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MfaVerificationDialogComponent } from '../mfa/mfa-verification-dialog/mfa-verification-dialog.component';
import { CollectionType } from '../../../common/enums/mfa.enum';
import { SessionTypeEnum } from '../../../common/models/SessionTypeEnum';
import { SpinnerService } from '../../../common/services/spinner.service';
import { NotificationService } from '../../../common/services/notification.service';
import { Message } from '../../../common/enums/message.enum';
import { AuthService } from '../../../common/services/auth.service';
import { PasswordService } from '../../../common/services/password/password.service';
import '../../../common/extensions/object.extension';
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrl: './my-profile.component.scss',
  providers: [DialogService]
})
export class MyProfileComponent extends BaseComponent implements OnInit {

  #mfaService = inject(MfaService);
  #userStateService = inject(UserStateService)
  #dialogService = inject(DialogService)
  #spinnerService = inject(SpinnerService)
  #notificationService = inject(NotificationService)
  #authService = inject(AuthService)
  #passwordService = inject(PasswordService)


  userDetails: UserDetails;
  lstQuestions$: Observable<SecurityQA[]>

  pinForm: FormGroup
  passwordForm: FormGroup
  formGroup: UntypedFormGroup;
  emailFormGroup: UntypedFormGroup;
  phoneFormGroup: UntypedFormGroup;

  collectionType: CollectionType
  contact: string

  changeEmailDialog = false
  changePhoneDialog = false
  changePasswordDialog = false
  contactValidateDialog = false


  #mfaSpinnerId = 'mfa-spinner'
  verificationHeader = ''

  lockButton = {
    label: 'Unlock Account',
    icon: 'pi-lock',
    isLocked: true
  };

  optDialogVisible = false;
  ref: DynamicDialogRef | undefined;

  constructor() {
    super()
    effect(() => {
      this.userDetails = this.#userStateService.getUser();
      // this.initFormGroup()
    })
  }

  ngOnInit(): void {
    this.userDetails = this.#userStateService.getUser();
    this.lstQuestions$ = this.#mfaService.getSecurityQAs();
    this.lockUnlockControls(true);
    this.initFormGroup()
    this.pinForm = new FormGroup({
      pin: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{6,6}')]),
    })

    this.passwordForm = this.#passwordService.initForm()
    this.passwordForm.addControl('currentpass', new FormControl('', [Validators.required]))
    this.setupContactSubscriptions();
  }

  lockUnlockControls(lockControls: boolean) {
    for (const control in this.formGroup?.controls) {
      if (lockControls) this.formGroup.controls[control].disable({ emitEvent: false, onlySelf: true });
      else this.formGroup.controls[control].enable({ emitEvent: false, onlySelf: true });
    }

    if (lockControls) {
      this.lockButton.isLocked = true;
      this.lockButton.icon = 'pi-lock';
      this.lockButton.label = 'Unlock Account';
    } else {
      this.lockButton.isLocked = false;
      this.lockButton.icon = 'pi-unlock';
      this.lockButton.label = 'Lock Account';

    }
  }

  initFormGroup() {
    this.formGroup = new FormGroup({
      firstName: new FormControl<string>({ value: this.userDetails.FirstName, disabled: this.lockButton.isLocked }),
      lastName: new FormControl<string>({ value: this.userDetails.LastName, disabled: this.lockButton.isLocked }),
      securityQuestion: new FormControl<number>({ value: this.userDetails.QuestionId, disabled: this.lockButton.isLocked }),
      securityAnswer: new FormControl<string>({ value: this.userDetails.QuestionAnswer, disabled: this.lockButton.isLocked })
    });

    this.emailFormGroup = new FormGroup({
      email: new FormControl<string>(undefined, [Validators.required, Validators.email])
    })
    this.phoneFormGroup = new FormGroup({
      mobilePhone: new FormControl<string>(undefined, [Validators.required, Validators.pattern('[0-9-()]*')]),
    })
  }

  onLockButtonClicked() {
    if (this.lockButton.isLocked) {
      this.#spinnerService.setLoading(true, this.#mfaSpinnerId);
      this.#mfaService.validate(SessionTypeEnum.VALIDATE)
        .pipe(finalize(() => {
          this.#spinnerService.setLoading(false, this.#mfaSpinnerId)
        }))
        .subscribe({
          next: () => {
            this.showVerificationDialog();
          },
          error: () => {
            this.#notificationService.showError(Message.ErrorMessage)
          }
        })
    } else {
      this.lockUnlockControls(true);
    }
  }

  saveBasicInformation() {
    this.subscriptions.add(this.#authService.saveBasicInformation(this.formGroup.value).subscribe({
      next: () => {
        this.#userStateService.setUser(this.patchUserInfo());
        this.#notificationService.showSuccess(Message.Success)
      },
      error: () => {
      }
    }))
  }

  changeEmail() {
    if (!this.lockButton.isLocked) {
      this.changeEmailDialog = true
      this.collectionType = CollectionType.email;
    }
  }

  changePhone() {
    if (!this.lockButton.isLocked) {
      this.changePhoneDialog = true
      this.collectionType = CollectionType.phone;
    }
  }

  onChangeEmailOrPhoneContinue() {
    if (this.changeEmailDialog) {
      this.contact = this.emailFormGroup.value.email;
    } else if (this.changePhoneDialog) {
      this.contact = this.phoneFormGroup.value.mobilePhone;
    }
    if (this.contact) {
      this.subscriptions.add(this.#mfaService.validateContact(this.collectionType, this.contact).pipe(tap(() => {
        this.changePhoneDialog = false
        this.changeEmailDialog = false
        this.setupVerificationHeader()
        this.contactValidateDialog = true
      })).subscribe({
        next: () => {
          this.#notificationService.showSuccess(Message.Success)
        },
        error: (err) => {
          console.log(err);
          this.#notificationService.showError(err.error, Message.Error);
        }
      }))
    }
  }

  setupVerificationHeader() {
    this.verificationHeader = this.collectionType === CollectionType.phone ? `To edit your phone number, please enter the six digit code sent to ${this.contact.Mask('phone')}` : `To edit your email, please enter the six digit code sent to ${this.contact.Mask('email')}`
  }
  changePassword() {
    if (!this.lockButton.isLocked) {
      this.changePasswordDialog = true
    }
  }

  onContactValidate() {
    this.subscriptions.add(
      this.#mfaService.validateContactPin(this.collectionType, this.contact, this.pinForm.value.pin, SessionTypeEnum.MFA)
        .subscribe({
          next: () => {
            this.#userStateService.setUser(this.patchContactInfo())
            this.contactValidateDialog = false
          },
          error: () => {
            this.#notificationService.showError(Message.Error)
          }
        })
    )
  }

  patchContactInfo() {
    if (this.collectionType === CollectionType.email) {
      return {
        ...this.userDetails,
        ContactEmail: this.contact
      }
    }
    return {
      ...this.userDetails,
      MobilePhone: this.contact
    }
  }

  patchUserInfo() {
    return {
      ...this.userDetails,
      FirstName: this.formGroup.controls['firstName'].value,
      LastName: this.formGroup.controls['lastName'].value,
      QuestionId: this.formGroup.controls['securityQuestion'].value,
      QuestionAnswer: this.formGroup.controls['securityAnswer'].value
    }
  }

  resendPin() {
    this.subscriptions.add(
      this.#mfaService.validateContact(this.collectionType, this.contact)
        .subscribe(
          {
            next: () => {
              this.#notificationService.showSuccess(Message.PinResentSuccess)
            },
            error: () => {
              this.#notificationService.showError(Message.Error)
            }
          }
        )
    )
  }

  savePassword() {
    this.subscriptions.add(this.#passwordService.updatePassword(this.passwordForm.value)

      .subscribe({
        next: () => {
          // notify the user
          this.changePasswordDialog = false
          this.#notificationService.showSuccess(Message.Success)
        },
        error: () => {
          // notify the user
          this.#notificationService.showError(Message.Error)
        }
      }))
  }

  showVerificationDialog() {
    this.ref = this.#dialogService.open(MfaVerificationDialogComponent, {
      data: { collectionType: CollectionType.email }, width: '80vw', modal: true, breakpoints: {
        '1120px': '80vw', 
        '575px': '90vw'
      }
    });
    this.ref.onClose.subscribe((value: boolean) => {
      if (value === true) {
        this.lockUnlockControls(false);
      } else {
        this.lockUnlockControls(true);
      }
    });
  }

  setupContactSubscriptions() {
    this.subscriptions.add(this.formGroup.controls['email']?.valueChanges.subscribe((val) => {
      this.contact = val
    }))

    this.subscriptions.add(this.formGroup.controls['mobilePhone']?.valueChanges.subscribe((val) => {
      this.contact = val
    }))
  }

  isEmailVerificationType() {
    return this.collectionType === CollectionType.email
  }

  isPhoneVerificationType() {
    return this.collectionType === CollectionType.phone
  }

  get passValue() {
    return this.passwordForm?.controls['pass'].value
  }

  isValidCharacter(passValue: string): boolean {
    const pattern = /([|'"\\])/;
    return !pattern.test(passValue);
  }

}
